import { Link } from "react-router-dom";
import { useEffect } from "react";
import annotatedCard from "../assets/annotated_card.png";
import annotatedCardMobile from "../assets/annotated_card_mobile.png";

const GuidanceResources: React.FC = () => {
  useEffect(() => {
    const handleHashChange = () => {
      const id = window.location.hash
        .substring(1)
        .replace("/guidance-resources#", "");
      console.log("id", id);
      const element = document.getElementById(id);
      if (element) {
        const offset = 100; // Adjust this value for the desired padding
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    };

    window.addEventListener("hashchange", handleHashChange);

    // Call the handler right away to handle the initial hash.
    handleHashChange();

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return (
    <div className="content">
      <section className="history-and-approach">
        <h2 className="page-headline-left">History and Approach</h2>
        <p className="intro-text">
          This guide is meant for anyone curious to know more about Asian
          American and Pacific Islander communities. So often our experiences
          have been flattened, but it is our hope to bring nuance and accuracy
          with this guide. The AAPI population is incredibly culturally and
          linguistically diverse, made up of about 50 ethnic groups that speak
          more than 100 languages. Because Pacific Islanders are an
          often-overlooked part of AAPI communities, reporters covering Pacific
          Islanders are also encouraged to read{" "}
          <a
            href="https://docs.google.com/document/d/1VvZnlnPjXYl98kGjI5zCOWGcIEdNeFIsUjwtZofKIRk/edit?tab=t.0"
            target="_blank"
            rel="noopener noreferrer"
          >
            this complementary reporting guidance
          </a>{" "}
          created by AAJA’s Pacific Islander Task Force in tandem with this
          guide. You can also find that resource and others below.
        </p>
        <div className="two-columns-container">
          <div className="column">
            <p>
              After all the anti-Asian attacks and disproportionate deaths of
              Pacific Islanders during the pandemic, we updated this guide to be
              more proactive instead of reactive in shaping how our communities
              are covered. It will help direct not only how we write about our
              communities but how we talk about them.
            </p>
            <p>
              This is not meant to be a dictionary or encyclopedia on all things
              Asian and Pacific Islander but an actionable and practical guide
              by AAPI journalists to add context and guidance to terms that are
              being used or that should be avoided in the news. As background,
              the last time the AAJA Style Guide was updated was over a decade
              ago. Since then, new terms, such as “kung flu,” have come into the
              vernacular that need to be addressed.
            </p>
            <p>
              Most importantly, this is our first long-overdue update, but it’s
              not the final version. This guide is meant to be a living document
              because how we describe and define our communities evolves over
              time. If you want to add or edit an entry, please{" "}
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfoDaQagNCE4NVNnm3dyfWZc0U-xHeKFmzD3t8KwuvGN1q0ZQ/viewform?usp=send_form"
                target="_blank"
                rel="noopener noreferrer"
              >
                fill out our form
              </a>
              . Our Style Guide Committee will discuss suggestions on a regular
              basis.
            </p>
          </div>
          <div className="column">
            <p>
              For context, the Style Guide Committee determined the following
              thresholds for whether an entry should be in the Style Guide:
            </p>
            <ul>
              <li>Does it relate to the AAPI experience?</li>
              <li>Is the term offensive and should not be used?</li>
              <li>Does the term require clarification from AAJA?</li>
            </ul>
            <p>
              When possible while covering our communities, be sure to keep the
              following points in mind:
            </p>
            <ul>
              <li>
                Be specific. Different generations, even within a community, may
                pronounce, order and spell things differently.
              </li>
              <li>
                Ask the source for what they prefer, especially regarding the
                spelling and order of their name.
              </li>
            </ul>
            <p>
              <b>Want to help make history?</b> Join the AAJA Style Guide
              Committee by filling out this form:{" "}
              <a href="https://forms.gle/YK94tKwQvCwhrJsJA" target="new">
                Volunteer with the AAJA Style Guide Committee.
              </a>
            </p>
          </div>
        </div>
      </section>
      <section className="featured-section" id="how-to-use">
        <div>
          <h2 className="section-headline">How to use the Style Guide</h2>
        </div>
        <p>
          There are multiple ways to use the Style Guide. And you can also help
          us improve the Style Guide by suggesting new entries.
        </p>
        <b>Using the search box.</b>
        <p>
          Already have a term you want to look up? Simply type it into the
          search box and see what results you get. If there are no entries
          matching your search, you will have an opportunity to suggest a new
          entry.
        </p>
        <b>Using the filters.</b>
        <p>
          Don’t have a specific term? You can also search by category. All
          entries fit into one or more categories. You can explore entries by
          selecting one or more of the category filters.
        </p>
        <p>
          Want to see all the entries in the Style Guide? You can organize the
          entries A to Z, or Z to A.
        </p>
        <img
          src={annotatedCard}
          className="annotated-card"
          alt="Annotated card"
        />
        <img
          src={annotatedCardMobile}
          alt="Annotated card"
          className="annotated-card-mobile"
        />
        <div>
          <Link to="/style-guide" className="donate-button">
            Go to style guide
          </Link>
        </div>
      </section>
      <section className="further-resources" id="resources">
        <h2>Further resources</h2>
        <ul className="two-columns-container">
          <div className="column">
            <li>
              <a
                href="https://docs.google.com/document/d/1VvZnlnPjXYl98kGjI5zCOWGcIEdNeFIsUjwtZofKIRk/edit?pli=1&tab=t.0"
                target="_blank"
                rel="noopener noreferrer"
              >
                AAJA Pacific Islander Task Force: A Journalist’s Guide to
                Covering Pacific Islanders
              </a>
            </li>
            <li>
              <a
                href="https://www.ameja.org/Media-Resource-Guide-for-Palestine-Israel"
                target="_blank"
                rel="noopener noreferrer"
              >
                Arab and Middle Eastern Journalists Association’s media resource
                guide for Palestine and Israel
              </a>
            </li>
            <li>
              <a
                href="https://anda-aaja.com/wp-content/uploads/2024/09/AAJA_GSR-Stylebook_EN.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Asian American Journalists Association-Asia’s Stylebook for
                Gender-Sensitive Reporting
              </a>
            </li>
            <li>
              <a
                href="https://aajastudio.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Asian American Journalists Association’s directory of experts
              </a>
            </li>
            <li>
              <a
                href="https://www.aaja.org/programs-and-initiatives/media-watch/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Asian American Journalists Association’s Media Watch
              </a>
            </li>
            <li>
              <a
                href="https://consciousstyleguide.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Conscious Style Guide
              </a>
            </li>
            <li>
              <a
                href="https://dartcenter.org/resources?type%5b0%5d=16"
                target="_blank"
                rel="noopener noreferrer"
              >
                Dart Center for Journalism & Trauma’s tip sheets
              </a>
            </li>
            <li>
              <a
                href="https://www.diversitystyleguide.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                The Diversity Style Guide
              </a>
            </li>
            <li>
              <a
                href="https://styleguide.globalpressjournal.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Global Press Style Guide
              </a>
            </li>
            <li>
              <a
                href="https://indigenousjournalists.org/resources/reporting-guides/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Indigenous Journalists Association’s reporting guides
              </a>
            </li>
          </div>
          <div className="column">
            <li>
              <a
                href="https://news.jrn.msu.edu/culturalcompetence/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Michigan State University School of Journalism’s Bias Busters
                cultural competence guides
              </a>
            </li>
            <li>
              <a
                href="https://milvetreporting.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Military & Veteran Affairs Reporting Guide
              </a>
            </li>
            <li>
              <a
                href="https://docs.google.com/document/d/1i0faNtXhlmMd4uMEy-KQ7FJUka2ZeIH0LVUaE9i2jTM/edit#heading=h.fumnn6wtg5o6"
                target="_blank"
                rel="noopener noreferrer"
              >
                Muslim Media: Shifting The Narrative toolkit
              </a>
            </li>
            <li>
              <a
                href="https://nabjonline.org/news-media-center/styleguide/"
                target="_blank"
                rel="noopener noreferrer"
              >
                National Association of Black Journalists’ Style Guide
              </a>
            </li>
            <li>
              <a
                href="https://nahj.org/wp-content/uploads/2021/03/NAHJ-Cultural-Compliance-Handbook-Revised-12-20-2.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                National Association of Hispanic Journalists’ Cultural
                Competence Handbook
              </a>
            </li>
            <li>
              <a
                href="https://nahj.org/wp-content/uploads/2024/07/2024-LGBTQIA-Style-Guide.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                National Association of Hispanic Journalists’ LGBTQIA+ Style
                Guide
              </a>
            </li>
            <li>
              <a
                href="https://journalists.org/resources/information-equity-database-2023/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Online News Association Information Equity Database
              </a>
            </li>
            <li>
              <a
                href="https://styleguide.transjournalists.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Trans Journalists Association’s Stylebook and Coverage Guide
              </a>
            </li>
            <li>
              <a
                href="https://languageplease.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Vox Media’s Language, Please guide
              </a>
            </li>
          </div>
        </ul>
      </section>
    </div>
  );
};

export default GuidanceResources;
