import { useState } from "react";
import { useAirtableData } from "../context/AirtableContext";
import { Link } from "react-router-dom";
import { handleCopyLink } from "../utils";
import Tooltip from "../components/Tooltip";
import Dedication from "../components/Dedication";
import { FaLink } from "react-icons/fa";

const LandingPage: React.FC = () => {
  const { data } = useAirtableData();
  const [tooltip, setTooltip] = useState<{
    visible: boolean;
    x: number;
    y: number;
  }>({ visible: false, x: 0, y: 0 });

  const allCategories = data.flatMap((item) => item.fields.Category);
  const uniqueCategories = new Set(allCategories);
  type CategoryKeys = typeof uniqueCategories extends Set<infer U> ? U : never;

  const categoryIconColors: { [key in CategoryKeys]: string } = {
    Identity: "#A1C6E2",
    "Do Not Use": "#E88393",
    History: "#BFA3B7",
    Culture: "#FED78F",
    "Racial Stereotypes": "#B6BFCD",
    Geography: "#BCDBA9",
    Immigration: "#FABB8D",
    COVID: "#B6E5DA",
  };

  const featuredData = data.filter((record) => record.fields.Feature === true);

  return (
    <div className="landing-page">
      <div className="content">
        <section className="hero-section">
          <h2>The Asian American Journalists Association Style Guide</h2>
          <p>
            This guide is meant for anyone who wants to better understand and
            more responsibly cover Asian American and Pacific Islander
            communities. Our diverse group is too often treated as a monolith
            and covered inaccurately or with stereotypes that can cause real
            harm. It is our hope that this guide can bring more nuance, accuracy
            and accountability to coverage of the AAPI population, which is made
            up of about 50 ethnic groups that speak more than 100 languages.
            Because Pacific Islanders are an often-overlooked part of AAPI
            communities, reporters covering Pacific Islanders are also
            encouraged to read{" "}
            <a
              href="https://docs.google.com/document/d/1VvZnlnPjXYl98kGjI5zCOWGcIEdNeFIsUjwtZofKIRk/edit?tab=t.0"
              target="_blank"
              rel="noopener noreferrer"
            >
              this complementary reporting guidance
            </a>{" "}
            created by AAJA’s Pacific Islander Task Force in tandem with this
            guide. You can also find{" "}
            <Link to="/guidance-resources#resources">
              that resource and others here
            </Link>{" "}
            on our guide.
          </p>
          <Link to="/guidance-resources#how-to-use" className="hero-button">
            How to use the guide
          </Link>
        </section>
        <section className="featured-section">
          <div className="featured-terms-header">
            <h2>Featured terms</h2>
            <Link to="/style-guide" className="donate-button">
              Explore the full guide
            </Link>
          </div>
          <div className="featured-terms">
            {featuredData.map((term, index) => (
              <div key={index} className="term-card">
                <div className="featured-term-card-header">
                  <div>
                    <button
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                        handleCopyLink(term.fields.HashId, event, setTooltip)
                      }
                      className="copy-link-button term"
                    >
                      <FaLink />
                    </button>
                    <span className="term">{term.fields.Entries}</span>
                  </div>
                  <Link
                    to={`/style-guide#${term.fields.HashId}`}
                    className="read-more-link"
                  >
                    Read More
                  </Link>
                </div>
                <p className="description">{term.fields.Description}</p>
                {term.fields.Category?.map(
                  (category: string, index: number) => (
                    <span
                      className="category-icon"
                      style={{
                        backgroundColor: categoryIconColors[category],
                        border: `1px solid ${categoryIconColors[category]}`,
                      }}
                      key={index}
                    >
                      {category}
                    </span>
                  )
                )}
              </div>
            ))}
          </div>
        </section>
        <Dedication link="/about" donateText="More about the guide" />
      </div>
      <Tooltip
        visible={tooltip.visible}
        x={tooltip.x}
        y={tooltip.y}
        text="Link copied!"
      />
    </div>
  );
};

export default LandingPage;
