import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

export const handleCopyLink = (
  id: string,
  event: React.MouseEvent,
  setTooltip: React.Dispatch<
    React.SetStateAction<{ visible: boolean; x: number; y: number }>
  >
) => {
  const link = `${window.location.origin}${window.location.pathname}#/style-guide#${id}`;
  navigator.clipboard.writeText(link).then(() => {
    setTooltip({
      visible: true,
      x: event.pageX + 10,
      y: event.pageY + 10,
    });

    setTimeout(() => {
      setTooltip({
        visible: false,
        x: 0,
        y: 0,
      });
    }, 2000);
  });
};

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const pagePath = location.pathname + location.search + location.hash;
    ReactGA.send({ hitType: "pageview", page: pagePath });
  }, [location]);
};
